import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#b1e2fa',
      main: '#004e9e',
      dark: '#05e6e3',
      contrastText: '#fff',
    },
  },
});
