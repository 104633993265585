import { Typography } from '@mui/material';

export const Banner = () => {
    return (
        <Typography
            id="pre"
            data-test-id="preformatted-text-line"
            sx={{
                display: 'block',
                height: '1px',
                border: '0',
                borderTop: '1px solid #ccc',
                margin: '1em 0',
                padding: '0',
            }}
        >
        {""}
      </Typography>
  )
};
