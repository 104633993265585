import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Button, Typography } from '@mui/material';
import { useCopyToClipboard } from 'react-use';
import { saveAs } from 'file-saver';
import { useEffect } from 'react';
import { Banner } from '../shared/Banner';


const ParseMessageBlock = ({ one_time, left }: { readonly one_time: boolean; readonly left: number; }) => {
   if (one_time) {
    var message = "This secret has been deleted from the database. It will not be available again. Make sure to copy/download it NOW.";
  }
  else {
    var left_formatted: string = new Date(Date.now() + left * 1000).toLocaleString("de-De");
    var message = `This secret will expire at ${left_formatted}. Make sure to copy/download it until then.`;
  }
  return (
    <div>
    <Banner></Banner>
    <Typography
      id="pre"
      data-test-id="preformatted-text-timing"
      sx={{
        color: '#f41d1d',
        backgroundColor: '#ffffff',
        padding: '20px',
        border: '3px solid #f41d1d',
        display: 'block',
        fontSize: '1rem',
        borderRadius: '4px',
        wordWrap: 'break-word',
        wordBreak: 'break-all',
        whiteSpace: 'pre-wrap',
        fontFamily: 'monospace, monospace',
      }}
    >
      {message}
      </Typography>
    </div>
  );
}

const RenderSecret = ({ secret, messageBlock }: { readonly secret: string; readonly messageBlock: string; }) => {
  const { t } = useTranslation();
  const [copy, copyToClipboard] = useCopyToClipboard();
  
  return (
    <div>
      <Typography variant="h4">{t('secret.titleMessage')}</Typography>
      <Button
        color={copy.error ? 'secondary' : 'primary'}
        onClick={() => copyToClipboard(secret)}
      >
        <FontAwesomeIcon icon={faCopy} /> {t('secret.buttonCopy')}
      </Button>
      <Typography
        id="pre"
        data-test-id="preformatted-text-secret"
        sx={{
          backgroundColor: '#ecf0f1',
          padding: '15px',
          border: '2px solid #cccccc',
          display: 'block',
          fontSize: '2rem',
          borderRadius: '4px',
          wordWrap: 'break-word',
          wordBreak: 'break-all',
          whiteSpace: 'pre-wrap',
          fontFamily: 'monospace, monospace', // https://github.com/necolas/normalize.css/issues/519#issuecomment-197131966
        }}
      >
        {secret}
      </Typography>
      {messageBlock}
    </div>
  );
};

const DownloadSecret = ({
  secret,
  fileName,
  messageBlock,
}: {
  readonly secret: string;
  readonly fileName: string;
  readonly messageBlock: string;
}) => {
  useEffect(() => {
    saveAs(
      new Blob([secret], {
        type: 'application/octet-stream',
      }),
      fileName,
    );
  }, [fileName, secret]);

  return (
    <div>
      {messageBlock}
    </div>    
  );
};

const Secret = ({
  secret,
  fileName,
  one_time,
  left
}: {
  readonly secret: string;
  readonly fileName?: string;
  readonly one_time: boolean;
  readonly left: number;
  }) => {

  var messageBlock = <ParseMessageBlock one_time={one_time} left={left}/>
   
  if (fileName) {
    return <DownloadSecret fileName={fileName} secret={secret} messageBlock={messageBlock} />;
  }
  return <RenderSecret secret={secret} messageBlock={messageBlock} />;
};

export default Secret;
